/* eslint-disable max-len */
import React from 'react';
import {
  Added,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
  Removed,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="August 2022"
      subnav="release-notes">
      <div id="August2022" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          The dog days of summer brought us a lot of sun, and even more
          improvements to the Uniform libraries. We added a few new features,
          deprecated the <code>environment</code> prop, and continued to improve
          accessibility in our design system. Look out for more exciting updates
          in that space next month!
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />
            <div className={styles.changesList}>
              <Added>
                All Icons table to the{' '}
                <Link href="/components/icons/design">Icons Overview</Link>{' '}
                page.
              </Added>
              <Added>
                <Link href="https://stylelint.io/">stylelint</Link> to our
                library build process.
              </Added>
              <Added>
                <code>includeValueInSearch</code> prop to{' '}
                <Link href="/components/forms/select/code?web#includeValueInSearch">
                  Select
                </Link>{' '}
                components.
              </Added>
              <Fixed>
                Date format information in the{' '}
                <Link href="/components/forms/input/design/">
                  Input component
                </Link>{' '}
                by updating help text to reflect browser locale.
              </Fixed>
              <Removed>
                Deprecated the <code>environment</code> prop on all components
                as we prefer to use the{' '}
                <Link href="/components/themes/environment/code/">
                  Environment component
                </Link>{' '}
                instead.
              </Removed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.20.3"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Added>
                New <code>displayType</code> prop and button or minimal button
                styles to the{' '}
                <Link href="/components/forms/checkbox/design">checkbox</Link>{' '}
                and .<Link href="/components/forms/radio/design">radio</Link>{' '}
                components.
              </Added>
              <Fixed>
                Accessibility style issues caused when static font-size
                declaration is removed from the root CSS.{' '}
                <Link href="https://sync.hudlnet.com/pages/viewpage.action?pageId=273421066#expand-ResultsfromStorybookTesting">
                  Learn more (internal).
                </Link>
                .
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="4.1.0"
              versionUrl="https://github.com/hudl/rn-uniform/releases"
            />
            <div className={styles.changesList}>
              <Added>
                New <code>displayType</code> prop and button or minimal button
                styles to the{' '}
                <Link href="/components/forms/checkbox/design">checkbox</Link>{' '}
                and .<Link href="/components/forms/radio/design">radio</Link>{' '}
                components.
              </Added>
            </div>
          </div>
          {/* <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />
            <div className={styles.changesList}>
              <Fixed>
                Sample change for{' '}
                <Link href="/components/modules/code">sample link</Link>.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Storybook" />
            <div className={styles.changesList}>
              <Removed>
                Sample change for{' '}
                <Link href="/components/modules/code">sample link</Link>.
              </Removed>
            </div>
          </div> */}
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
